<template>
  <div>
    <div class="card mb-3" id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'>
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-shopping-cart"></i>
              Printers
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Manage manage all availables Printers
            </p>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div id="orders-actions" class="d-flex">
              <div class="search-box h1 me-1">
                <input class="form-control search-input fuzzy-search" type="text" placeholder="Search..."
                  aria-label="Search" v-model="searchValue" />
                <span class="fas fa-search search-box-icon"></span>
              </div>
              <button @click="addNew" type="button" data-bs-toggle="modal" data-bs-target="#add-modal"
                class="btn btn-falcon-primary rounded-circle btn-sm h2 ms-2">
                <i class="fas fa-plus"></i>
              </button>
              <button @click="forceReload" class="btn btn-falcon-primary rounded-circle btn-sm h2 ms-2">
                <i class="fa-solid fa-arrows-rotate"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <EasyDataTable id="tour-step-1" table-class-name="customize-table" alternating buttons-pagination
          :headers="headers" :items="resources" :search-field="searchField" :search-value="searchValue"
          :loading="loading">
          <template #loading>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
          <template #item-action="item">
            <div class="dropdown font-sans-serif position-static">
              <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button" id="order-dropdown-0"
                data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
                <span class="fas fa-ellipsis-h fs--1"></span>
              </button>
              <div class="dropdown-menu dropdown-menu-end border py-0" aria-labelledby="order-dropdown-0">
                <div class="py-2">
                 
                  <a 
                  @click="editItem(item)" 
                  class="dropdown-item" 
                  role="button" 
                  data-bs-toggle="modal"
                  data-bs-target="#edit-modal"
                  >Edit</a>

                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item text-danger" 
                  role="button" 
                  @click="deleteRecource(item)"
                  >Delete</a>
                </div>
              </div>
            </div>
          </template>
        </EasyDataTable>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>

    <!-- Start edit modal  -->
    <div class="modal fade" 
    id="edit-modal"
     data-bs-keyboard="false"
      data-bs-backdrop="static" 
      tabindex="-1"
      aria-labelledby="add-modalLabel"
       aria-hidden="true">
      <div class="modal-dialog modal-md mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Edit Printers</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-12">
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row">
                      <div class="mb-3 col-6">
                        <label class="col-form-label" for="recipient-name">Name</label>
                        <input class="form-control" id="recipient-name" type="text" v-model="formData.title" :class="
                          v$.formData.title.$dirty &&
                            v$.formData.title.$invalid
                            ? `is-invalid`
                            : v$.formData.title.$dirty
                              ? `is-valid`
                              : ``
                        " />
                        <div v-for="error of v$.formData.title.$errors" :key="error.$uid" class="invalid-feedback"
                          style="color: red">
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-6">
                          <label class="col-form-label" for="recipient-name">Slug</label>
                          <input class="form-control" id="recipient-name" type="text" v-model="formData.slug" :class="
                            v$.formData.slug.$dirty &&
                              v$.formData.slug.$invalid
                              ? `is-invalid`
                              : v$.formData.slug.$dirty
                                ? `is-valid`
                                : ``
                          " />
                          <div v-for="error of v$.formData.slug.$errors" :key="error.$uid" class="invalid-feedback"
                            style="color: red">
                            This field is invalid
                          </div>
                        </div>
                      <div class="mb-3 col-6">
                        <label class="col-form-label" for="message-text">IP Address</label>
                        <input class="form-control" id="recipient-name" type="text" v-model="formData.ip_address" :class="
                          v$.formData.ip_address.$dirty &&
                            v$.formData.ip_address.$invalid
                            ? `is-invalid`
                            : v$.formData.ip_address.$dirty
                              ? `is-valid`
                              : ``
                        " />
                        <div v-for="error of v$.formData.ip_address.$errors" :key="error.$uid" class="invalid-feedback"
                          style="color: red">
                          This field is invalid
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row">
                      <label class="col-form-label" for="message-text">Description</label>
                      <p class="text-word-break fs--1">
                        <textarea v-model="formData.description" class="form-control" id="exampleFormControlTextarea1"
                          rows="3" :class="
                            v$.formData.description.$dirty &&
                              v$.formData.description.$invalid
                              ? `is-invalid`
                              : v$.formData.description.$dirty
                                ? `is-valid`
                                : ``
                          "></textarea>
                      <div v-for="error of v$.formData.description.$errors" :key="error.$uid" class="invalid-feedback"
                        style="color: red">
                        This field is invalid
                      </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block" type="button" @click="updateResource()">
              <span class="fa fa-save me-2" data-fa-transform=""></span>
              <span class="d-none d-sm-inline-block ms-1">Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End edit modal  -->
    <!-- Start add modal  -->
    <div class="modal fade" 
    id="add-modal"
     data-bs-keyboard="false"
      data-bs-backdrop="static" 
      tabindex="-1"
      aria-labelledby="add-modalLabel"
       aria-hidden="true">
      <div class="modal-dialog modal-md mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">New Printers</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-12">
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row">
                      <div class="mb-3 col-6">
                        <label class="col-form-label" for="recipient-name">Name</label>
                        <input class="form-control" id="recipient-name" type="text" v-model="formData.title" :class="
                          v$.formData.title.$dirty &&
                            v$.formData.title.$invalid
                            ? `is-invalid`
                            : v$.formData.title.$dirty
                              ? `is-valid`
                              : ``
                        " />
                        <div v-for="error of v$.formData.title.$errors" :key="error.$uid" class="invalid-feedback"
                          style="color: red">
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-6">
                          <label class="col-form-label" for="recipient-name">Slug</label>
                          <input class="form-control" id="recipient-name" type="text" v-model="formData.slug" :class="
                            v$.formData.slug.$dirty &&
                              v$.formData.slug.$invalid
                              ? `is-invalid`
                              : v$.formData.slug.$dirty
                                ? `is-valid`
                                : ``
                          " />
                          <div v-for="error of v$.formData.slug.$errors" :key="error.$uid" class="invalid-feedback"
                            style="color: red">
                            This field is invalid
                          </div>
                        </div>
                      <div class="mb-3 col-6">
                        <label class="col-form-label" for="message-text">IP Address</label>
                        <input class="form-control" id="recipient-name" type="text" v-model="formData.ip_address" :class="
                          v$.formData.ip_address.$dirty &&
                            v$.formData.ip_address.$invalid
                            ? `is-invalid`
                            : v$.formData.ip_address.$dirty
                              ? `is-valid`
                              : ``
                        " />
                        <div v-for="error of v$.formData.ip_address.$errors" :key="error.$uid" class="invalid-feedback"
                          style="color: red">
                          This field is invalid
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row">
                      <label class="col-form-label" for="message-text">Description</label>
                      <p class="text-word-break fs--1">
                        <textarea v-model="formData.description" class="form-control" id="exampleFormControlTextarea1"
                          rows="3" :class="
                            v$.formData.description.$dirty &&
                              v$.formData.description.$invalid
                              ? `is-invalid`
                              : v$.formData.description.$dirty
                                ? `is-valid`
                                : ``
                          "></textarea>
                      <div v-for="error of v$.formData.description.$errors" :key="error.$uid" class="invalid-feedback"
                        style="color: red">
                        This field is invalid
                      </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block" type="button" @click="saveResource()">
              <span class="fa fa-save me-2" data-fa-transform=""></span><span
                class="d-none d-sm-inline-block ms-1">Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import CartService from "../services/cart.service";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import ProductService from "../services/product.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import MenuGroupService from "../services/menu_group.service";
import MenuItemSizeService from "../services/menu_item_size.service";
import OrderTypeService from "../services/order_types.service";

import PrinterService from "../services/printer.service";

const now = new Date();

export default {
  mixins: [mixins],
  name: "CustomersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        title: { required },
        ip_address: { required },
        description: { required },
        slug: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      order_types: [],
      selected_category: "",
      menu_item_sizes: [],
      productCategories: [],
      groups: [],
      searchValue: "",
      table_keys: 1,
      formData: {
        group_id: "",
        active: 0,
        visible: 1,
        unit_name: "",
      },
      resources: [],
      orders_table_key: 0,
      searchField: ["id", "title", "description"],
      headers: [
        { text: "#", value: "id", sortable: true, widh: 10 },
        { text: "Name", value: "title", sortable: true },
        { text: "Slug", value: "slug", sortable: true },
        { text: "IP Adress", value: "ip_address" },
        { text: "Description", value: "description" },
        { text: "Action", value: "action" },
      ],
    };
  },
  created() {
    // fetching data from api
    this.getData();
  },
  watch: {},
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    addNew() {
      this.formData = {};
    },
    editItem(item) {
      console.log(item);
      this.formData = item;
    },
    async selectOrderType(e) {
      this.$store.commit("SET_LOADING", true);
      await this.getItemsByOrder(e.target.value);
      this.$store.commit("SET_LOADING", false);
    },
    categorySelected(e) {
      console.log(e);
      this.getItemsByOrder(e.target.value);
    },
    sortItems(list) {
      return list.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    getMenuCategories() {
      this.$store.commit("SET_LOADING", true);
      return ProductService.getMenuCategories().then(
        (response) => {
          if (response.data.status == "success") {
            this.productCategories = response.data.data;
            this.getItemsByOrder(response.data.data.at(0).id);
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getItemsByOrder(item_id) {
      this.$store.commit("SET_LOADING", true);
      return CartService.byOrder(item_id).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = this.sortItems(response.data.data);
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    groupSelected(e) {
      this.formData.group_id = e.target.value;
    },
    getMenuItemSize() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.menu_item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getGroups() {
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.title.$touch();
      this.v$.formData.ip_address.$touch();
      this.v$.formData.description.$touch();
      this.v$.formData.slug.$touch();
      console.log(this.v$.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      PrinterService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource(resource) {
      this.v$.formData.title.$touch();
      this.v$.formData.ip_address.$touch();
      this.v$.formData.description.$touch();
      this.v$.formData.slug.$touch();
      console.log(this.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      PrinterService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        PrinterService.delete(resource).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return PrinterService.getAll().then(
        (response) => {
          console.log(response);
          console.log(typeof response);

          if (response.data.code == 200) {
            console.log(response);
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
