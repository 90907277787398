<template>
  <div>
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">
      <div class="container" data-layout="container">
        <div class="row flex-center min-vh-100 py-6">
          <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <div class="card">
              <div class="card-header py-4">
                <a class="d-flex flex-center" href="https://bulla.co.ke">
                  <img class="me-2" src="assets/img/logo.png" alt="" height="36" />
                </a>
                <span class="font-sans-serif fs--1 d-flex flex-center fst-itali"
                  >Management Made a breeze</span
                >
                <!-- Server online  -->
                <div v-if="!serverOnline" class="alert alert-danger mt-3" role="alert">
                  Sorry! this server is currently offline, please check your internet!
                </div>
                <!-- End Server online  -->
              </div>

              <div class="position-relative">
                <hr />
                <div class="divider-content-center">
                  <h5>Log in</h5>
                </div>
              </div>
              <div class="card-body">
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    <div class="btn-group">
                      <div class="dropdown font-sans-serif d-inline-block">
                        <a
                          class="dropdown-toggle"
                          :class="username_focused ? ' text-warning' : 'text-secondary'"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <!-- <i class="far fa-question-circle mt-1"></i> -->
                          <i class="far fa-user ml-1 mt-1"></i>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <div
                            class="scrollbar list"
                            style="max-height: 250px; width: 270px"
                          >
                            <h6
                              class="dropdown-header fw-bold text-uppercase px-card fs--2 pt-0 pb-2"
                            >
                              Select users
                            </h6>
                            <div class="dropdown-divider"></div>
                            <a
                              v-for="(user, i) in users"
                              :key="i"
                              class="dropdown-item"
                              data-toggle="dropdown"
                              @click="setUser(user.email)"
                              >{{ user.first_name }} {{ user.last_name }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                  <input
                    class="form-control"
                    type="email"
                    v-model="formData.email"
                    placeholder="Email/Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    @focus="usernameFocus(true)"
                    @blur="usernameFocus(false)"
                    :class="
                      v$.formData.email.$dirty && v$.formData.email.$invalid
                        ? `is-invalid`
                        : v$.formData.email.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.email.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    Oops! The email address is invalid.
                  </div>
                </div>
                <div class="input-group mb-3">
                  <span
                    class="input-group-text"
                    id="basic-addon1"
                    :class="password_focused ? 'text-warning' : 'text-secondary'"
                  >
                    <i class="fas fa-unlock-alt me-2 ms-2"></i>
                  </span>
                  <input
                    v-model="formData.password"
                    class="form-control input-lg"
                    type="password"
                    placeholder="Password"
                    aria-label="Username"
                    @focus="passwordFocus(true)"
                    @blur="passwordFocus(false)"
                    aria-describedby="basic-addon1"
                    v-on:keydown.enter="handleLogin"
                    :class="
                      v$.formData.password.$dirty && v$.formData.password.$invalid
                        ? `is-invalid`
                        : v$.formData.password.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.password.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    Oops! The email address is invalid.
                  </div>
                </div>
                <div class="row flex-between-center">
                  <div class="col-auto">
                    <div class="form-check mb-0">
                      <input
                        v-model="formData.remember_me"
                        class="form-check-input"
                        type="checkbox"
                        id="basic-checkbox"
                        checked="checked"
                      /><label class="form-check-label mb-0" for="basic-checkbox"
                        >Remember me</label
                      >
                    </div>
                  </div>
                </div>
                <div class="mb-3 d-flex">
                  <div class="col-auto mt-4 ms-1">
                    <a
                      role="button"
                      class="fs--1"
                      @click="$router.push('/forgot-password')"
                      href="javascript:;"
                      >Forgot Password?</a
                    >
                  </div>

                  <button class="btn btn-primary w-20 mt-3 ms-auto" @click="handleLogin">
                    Log in
                    <i class="fa-regular fa-right-to-bracket"></i>
                  </button>
                </div>

                <div class="position-relative mt-4">
                  <hr />
                  <div class="divider-content-center">
                    <span class="mb-0 undefined me-1">or</span>
                    <span
                      ><a @click="goToRegister" role="button" href="javascript:;"
                        >Create an account</a
                      ></span
                    >
                  </div>
                </div>
                <div class="row g-2 mt-2">
                  <span class="font-sans-serif fs--2 d-flex flex-center"
                    >POS | <a class="mb-0 text-500">Version {{ version }}</a></span
                  >
                </div>

                <!-- <div class="row g-2 mt-2">
                <div class="col-sm-6"><a class="btn btn-outline-google-plus btn-sm d-block w-100"
                    href="login.html#"><span class="fab fa-google-plus-g me-2" data-fa-transform="grow-8"></span>
                    google</a></div>
                <div class="col-sm-6"><a class="btn btn-outline-facebook btn-sm d-block w-100" href="login.html#"><span
                      class="fab fa-facebook-square me-2" data-fa-transform="grow-8"></span> facebook</a></div>
              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useVuelidate } from "@vuelidate/core";
import UserService from "../services/user.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { required, email, minLength } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "LoginView",
  components: {
    LoaderOverlay,
  },
  data() {
    return {
      serverOnline: true,
      username_focused: false,
      password_focused: false,
      formData: {
        email: "",
        password: "",
        remember_me: false,
      },
      users: [],
      version: process.env.VUE_APP_VERSION,
    };
  },
  validations() {
    return {
      formData: {
        password: { required },
        email: { required, email },
      },
    };
  },
  created() {
    this.getLoginUsers();
  },
  mounted() {
    // Theme setup custome to bellalisa
    localStorage.setItem("navbarStyle", "card");
    localStorage.setItem("isFluid", true);
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  methods: {
    goToRegister() {
      this.$router.push("/register");
    },
    gotToForgotPassword() {
      this.$router.push("/forgot-password");
    },
    usernameFocus(val) {
      this.username_focused = val;
    },
    passwordFocus(val) {
      this.password_focused = val;
    },
    setUser(email) {
      this.username_key = email;
      this.username_key = email;
      this.formData.email = email;
    },
    gotToRegister() {
      this.$router.push("/register");
    },
    getLoginUsers() {
      this.$store.commit("SET_LOADING", true);
      return UserService.getLoginUsers().then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            this.users = response.data.data;
            this.serverOnline = true;
          } else {
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          console.log(error.response);
          this.serverOnline = false;
          setTimeout(() => {
            this.getLoginUsers();
          }, 1000);
          toast.error(error.response.data.message || error.mesage || error.toString(), {
            autoClose: 3000,
          });
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    handleLogin() {
      if (this.formData.email && this.formData.password) {
        this.$store.commit("SET_LOADING", true);
        this.$store.dispatch("auth/login", this.formData).then(
          (response) => {
            if (response.status == 200) {
              toast.success("Welcome aboard!", {
                autoClose: 3000,
              });
              if (response.data.user.is_admin == 1) {
                setTimeout(() => {
                  this.$router.push("/dashboard").then(() => {
                    // this.$router.go()
                  });
                }, 1500);
              } else {
                setTimeout(() => {
                  this.$router.push("/home").then(() => {
                    // this.$router.go()
                  });
                }, 1500);
              }
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            toast.error(
              error.response.data.message ||
                (error.response && error.response.data) ||
                error.mesage ||
                error.toString(),
              { autoClose: 3000 }
            );
          }
        );
      }
    },
  },
};
</script>
